import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
  ViewEncapsulation
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { isoCountryCodes } from '@assets/data/ISO-country-codes';
import { Country } from '@core/customer/customer.types';
import { EmployeeService } from '@core/employee/employee.service';
import { Employee } from '@core/employee/employee.types';
import { MerchantService } from '@core/merchant/merchant.service';
import { Merchant } from '@core/merchant/merchant.types';
import { OrderService } from '@core/order/order.service';
import { Order } from '@core/order/order.types';
import { ProductOrder } from '@core/product/product-order.types';
import { Attribute, Product, ProductType, VariantItem } from '@core/product/product.types';
import { SessionService } from '@core/session/session.service';
import { SessionData } from '@core/session/session.type';
import { SettingsService } from '@core/settings/settings.service';
import { Settings } from '@core/settings/settings.types';
import { FlatShippingRate } from '@core/shipping/shipping-rate-flat.types';
import { ShippoShippingRate } from '@core/shipping/shipping-rate-shippo.types';
import { UserService } from '@core/user/user.service';
import { FuseDrawerService } from '@fuse/components/drawer';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { cloneDeep, isEqual } from 'lodash-es';
import { combineLatest, distinctUntilChanged, filter, Subject, takeUntil } from 'rxjs';
import moment from 'moment';
import { ProductService } from '@core/product/product.service';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoCurrencyPipe, TranslocoDatePipe, TranslocoLocaleModule } from '@jsverse/transloco-locale';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FuseAlertComponent } from '@fuse/components/alert';
import { SafePipe } from '@core/utils/safe.pipe';

/**
 * Component that displays the content of the basket.
 */
@Component({
  selector: 'app-basket-content',
  templateUrl: './basket.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    TranslocoModule,
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatDividerModule,
    MatMenuModule,
    MatRippleModule,
    MatTooltipModule,
    MatButtonModule,
    FuseAlertComponent,
    TranslocoLocaleModule,
    TranslocoDatePipe,
    TranslocoCurrencyPipe,
    SafePipe
  ],
  providers: [
    TranslocoDatePipe,
    TranslocoCurrencyPipe,
    SafePipe
  ]
})
export class BasketContentComponent implements OnInit, OnDestroy {

  /**
   * The list of quantity selectors in the component.
   */
  @ViewChildren('quantitySelector') quantitySelectors: QueryList<MatSelect>;

  /**
   * Whether to show the action buttons in the component.
   */
  @Input() showActions: boolean = true;

  /**
   * Whether to highlight the shippable products in the component.
   */
  @Input() highlightShippableProducts: boolean = false;

  /**
   * Event emitter that emits when the component is closed.
   */
  @Output() close = new EventEmitter<any>();

  /**
   * The merchant associated with the basket.
   */
  merchant: Merchant;

  /**
   * The settings associated with the basket.
   */
  settings: Settings;

  /**
   * The list of employees associated with the basket.
   */
  employees: Employee[];

  /**
   * The session data associated with the basket.
   */
  sessionData: SessionData;

  /**
   * The order associated with the basket.
   */
  order: Order;

  /**
   * The product type enumeration.
   */
  ProductType = ProductType;

  /**
   * The form group for the component.
   */
  form: UntypedFormGroup;

  /**
   * The country code of the user.
   */
  userCountryCode: string;

  /**
   * The list of countries.
   */
  countries: Country[];

  /**
   * The template data for the component.
   */
  tpl: {
    nbProducts: number,
    orderTotal: number,
    orderCurrency: string,
    productAttributes: { [productId: string]: Attribute[] },
    variantItemOptions: { [attribute: string]: string }[],
    variantItemCustomFieldValues: any[][],
    hasMixedBasket: boolean,
    hasServicesOnly: boolean,
    hasServices: boolean,
    hasProducts: boolean,
    employeesNames: { [employeeId: string]: string },
    productOrder: {
      [productOrderId: number]: {
        firstProductMinPicture: {
          url: string;
          srcset: string;
          sizes: string;
        },
        availableQuantities: number[],
        displayedStartTime: string,
        displayedEndTime: string,
        displayedDate: string,
        unitPrice: number
      }
    },
    total: number,
    subtotal: number,
    couponDiscount: number,
    shippingCosts: number,
    basketIsEmpty: boolean,
    nProducts: number,
    showCouponDiscount: boolean,
    showCheckoutButton: boolean,
    showShoppingButton: boolean,
    showShippingCosts: boolean,
    showEstimatedShippingCosts: boolean,
    showPaymentMethods: boolean,
    shippingRates: {
      countries: string[];
      price: number;
      estimatedDays: number;
      destinationType: 'home' | 'relay';
    }[],
    isShippingDetailsAvailable: boolean,
    paymentMethods: {
      id: string;
      name: string;
    }[];
  } = {
      nbProducts: 0,
      orderTotal: 0,
      orderCurrency: 'EUR',
      productAttributes: {},
      variantItemOptions: [],
      variantItemCustomFieldValues: [],
      hasMixedBasket: false,
      hasServicesOnly: false,
      hasServices: false,
      hasProducts: false,
      employeesNames: {},
      productOrder: {},
      total: 0,
      subtotal: 0,
      couponDiscount: 0,
      shippingCosts: 0,
      basketIsEmpty: false,
      nProducts: 0,
      showCouponDiscount: false,
      showCheckoutButton: false,
      showShoppingButton: false,
      showShippingCosts: false,
      showEstimatedShippingCosts: false,
      showPaymentMethods: false,
      shippingRates: [],
      isShippingDetailsAvailable: false,
      paymentMethods: []
    };

  /**
   * The subject that emits when the component is destroyed.
   */
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor for the component.
   *
   * @param _merchantService - The merchant service.
   * @param _productService - The product service.
   * @param _settingsService - The settings service.
   * @param _employeeService - The employee service.
   * @param _orderService - The order service.
   * @param _formBuilder - The form builder.
   * @param _fuseDrawerService - The drawer service.
   * @param _sessionService - The session service.
   * @param _userService - The user service.
   * @param _router - The router.
   * @param _activatedRoute - The activated route.
   * @param _changeDetectorRef - The change detector reference.
   * @param _fuseMediaWatcherService - The media watcher service.
   */
  constructor(
    private _merchantService: MerchantService,
    private _productService: ProductService,
    private _settingsService: SettingsService,
    private _employeeService: EmployeeService,
    private _orderService: OrderService,
    private _formBuilder: UntypedFormBuilder,
    private _fuseDrawerService: FuseDrawerService,
    private _sessionService: SessionService,
    private _userService: UserService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _changeDetectorRef: ChangeDetectorRef,
    private _fuseMediaWatcherService: FuseMediaWatcherService
  ) {
    this.form = this._formBuilder.group({
      quantities: this._formBuilder.array([])
    });
    this.countries = isoCountryCodes;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Returns the form array of quantities from the parent form.
   * @returns {UntypedFormArray} The form array of quantities.
   */
  get formQuantities(): UntypedFormArray {
    return this.form.get('quantities') as UntypedFormArray;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * Initializes the component and subscribes to various observables.
   * 
   * @remarks
   * This method subscribes to the following observables:
   * - ActivatedRoute data and Router events to detect page changes and update the component accordingly.
   * - MerchantService, SettingsService, EmployeeService, and SessionService to update the component with the latest data.
   * - FuseMediaWatcherService to detect media changes and update the component accordingly.
   * - UserService to get the user's country code and update the shipping costs.
   * 
   * @returns void
   */
  ngOnInit(): void {

    /** IMPORTANT
    * The change of route and route data cannot be combined in a single subscription with the others
    */
    combineLatest([
      this._activatedRoute.data,
      this._router.events, // Subscribe to a page change (actually to receiving new data from the page)
    ])
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter(([data, event]: [Data, any]) => event instanceof NavigationEnd),
        distinctUntilChanged((previous: any, current: any) => isEqual(previous, current))
      )
      .subscribe(([data, event]: [Data, NavigationEnd]) => {
        this._fillInForm();
        this._updateTemplateData();
        // Mark for check
        this._changeDetectorRef.markForCheck();
      });

    combineLatest([
      this._merchantService.merchant$,
      this._settingsService.settings$,
      this._employeeService.employees$,
      this._sessionService.data$
    ])
      .pipe(
        takeUntil(this._unsubscribeAll),
        distinctUntilChanged((previous: any, current: any) => isEqual(previous, current))
      )
      .subscribe(([merchant, settings, employees, sessionData]: [Merchant, Settings, Employee[], SessionData]) => {
        this.merchant = merchant;
        this.settings = settings;
        this.employees = employees;
        this.sessionData = cloneDeep(sessionData);
        this.order = cloneDeep(sessionData.basket);
        this._fillInForm();
        this._updateTemplateData();
        // Mark for check
        this._changeDetectorRef.markForCheck();
      });

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Mark for check
        this._changeDetectorRef.markForCheck();
      });

    this._userService.getCountryCode()
      .subscribe((countryCode: string) => {
        this.userCountryCode = countryCode;
        // Update the shipping costs
        this._refreshShippingCosts();
        // Mark for check
        this._changeDetectorRef.markForCheck();
      });
  }

  /**
   * Lifecycle hook that is called when the component is destroyed.
   * Unsubscribes from all subscriptions to prevent memory leaks.
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Formats a moment object into a string representing the time slot in the format "HH:mm".
   * @param date - The moment object to format.
   * @returns A string representing the time slot in the format "HH:mm".
   */
  private _formatTimeSlot(date: moment.Moment): string {
    const hours: string = date.hours() < 10 ? `0${date.hours()}` : `${date.hours()}`;
    const minutes: string = date.minutes() < 10 ? `0${date.minutes()}` : `${date.minutes()}`;
    return `${hours}:${minutes}`;
  }

  /**
   * Fills in the form quantities for the order's product orders.
   * This is a hack to force mat-select to reload its value.
   * @private
   * @returns {void}
   */
  private _fillInForm(): void {
    if (!this.order) {
      return;
    }
    this.formQuantities.clear();
    this.order.qartOrder.productOrders.forEach(productOrder => {
      this.formQuantities.push(this._formBuilder.control(productOrder.quantity), { emitEvent: true });
    });
    /**
     * This is a hack to force mat-select to reload its value
     */
    // Mark for check
    this._changeDetectorRef.markForCheck();
    if (this.quantitySelectors && this.quantitySelectors.length > 0) {
      this.quantitySelectors.forEach((quantitySelector: MatSelect) => {
        const productOrderIdx = quantitySelector._elementRef.nativeElement.getAttribute('product-order-idx');
        // We check this condition because after paying the order, 
        // the basket is cleared but this code is executed before the corresponding selectors are destroyed
        if (this.order.qartOrder.productOrders.length > productOrderIdx) {
          quantitySelector.value = this.order.qartOrder.productOrders[productOrderIdx].quantity;
        }
      });
    }
    // Mark for check
    this._changeDetectorRef.markForCheck();
  }

  /**
   * Returns the fee amount of the specified type for the current order.
   * @param type - The type of fee to retrieve.
   * @returns The fee amount in the currency of the order.
   */
  private _getOrderFee(type: string): number {
    if (this.order.stripePaymentIntent?.charges?.data && this.order.stripePaymentIntent?.charges?.data.length > 0) {
      const balanceTransaction = this.order.stripePaymentIntent.charges.data[0].balance_transaction;
      if (balanceTransaction && balanceTransaction.fee_details) {
        const fee = balanceTransaction.fee_details.find(fee => fee.type === type);
        if (fee) {
          if (balanceTransaction.exchange_rate) {
            return (fee.amount / balanceTransaction.exchange_rate) / 100;
          } else {
            return fee.amount / 100;
          }
        }
      }
    }
    return 0;
  }

  /**
   * Calculates the total amount of the order by subtracting the Stripe fee and application fee from the total.
   * @returns The total amount of the order.
   */
  private _getOrderTotal(): number {
    if (this.order) {
      let amount: number = this.order.qartOrder.total;
      amount -= this._getOrderFee('stripe_fee');
      amount -= this._getOrderFee('application_fee');
      return amount;
    } else {
      return 0;
    }
  }

  /**
   * Returns an array of attributes for a given product.
   * @param product The product to get attributes for.
   * @returns An array of attributes.
   */
  private _getProductAttributes(product: Product): Attribute[] {
    const attributes: Attribute[] = [];
    // Compute the set of attributes for which a variant exists          
    const attributeNames = Array.from(new Set(...product.variantItems.map(variantItem => variantItem.attributes.map(attribute => attribute.name))));
    for (let attributeName of attributeNames) {
      const options = Array.from(new Set(product.variantItems.map(variantItem => variantItem.attributes.find(attribute => attribute.name === attributeName).option)));
      attributes.push({
        type: 'whatever',
        name: attributeName,
        description: '',
        options: options,
        displayedAsFilter: false
      });
    }
    return attributes;
  }

  /**
   * Returns the option value of a given attribute name for a variant item.
   * @param variantItem - The variant item to retrieve the attribute option from.
   * @param attributeName - The name of the attribute to retrieve the option value for.
   * @returns The option value of the attribute for the variant item, or an empty string if the variant item is null or the attribute is not found.
   */
  private _getVariantItemOption(variantItem: VariantItem, attributeName: string): string {
    let option: string = '';
    if (variantItem) {
      option = variantItem.attributes.find(attribute => attribute.name === attributeName).option;
    }
    return option;
  }

  /**
   * Updates the template data based on the current state of the component's properties.
   * If any required properties are missing, the method returns early without updating the template data.
   * The updated template data is stored in the `tpl` property of the component.
   * @returns void
   */
  private _updateTemplateData(): void {
    if (!this.merchant || !this.order || !this.settings || !this.employees || !this.sessionData) {
      return;
    }
    this.tpl.orderCurrency = this.settings.currency;
    this.tpl.orderTotal = this._getOrderTotal();
    for (let productOrderIdx = 0; productOrderIdx < this.order.qartOrder.productOrders.length; productOrderIdx++) {
      const productOrder = this.order.qartOrder.productOrders[productOrderIdx];
      this.tpl.productAttributes[productOrder.product._id] = this._getProductAttributes(productOrder.product);
      this.tpl.variantItemOptions[productOrderIdx] = {};
      for (const attribute of this.tpl.productAttributes[productOrder.product._id]) {
        this.tpl.variantItemOptions[productOrderIdx][attribute.name] = this._getVariantItemOption(productOrder.selectedVariant, attribute.name);
      }
      this.tpl.variantItemCustomFieldValues[productOrderIdx] = [];
      for (let i = 0; i < productOrder.product.customFields?.length; i++) {
        const value: any = productOrder.selectedVariant.customFieldValues[i];
        let valueStr: string = '';
        if (value == null) {
          valueStr = '';
        } else if (Array.isArray(value)) {
          valueStr = value.join(', ');
        } else {
          valueStr = `${value}`;
        }
        // Keep the string value below 128 characters for display
        if (valueStr.length > 128) {
          valueStr = valueStr.substring(0, 128) + '...';
        }
        this.tpl.variantItemCustomFieldValues[productOrderIdx].push(valueStr);
      }
      const photoUrls: string[] = this._productService.getPhotoUrls(productOrder.product);
      const set: {srcset: string, sizes: string} = this._productService.getPhotoSrcSet(productOrder.product, 0);
      this.tpl.productOrder[productOrder.id] = {
        firstProductMinPicture: {
          url: photoUrls.length > 0 ? photoUrls[0] : '',
          srcset: photoUrls.length > 0 ? set.srcset : null,
          sizes: photoUrls.length > 0 ? set.sizes : null
        },
        availableQuantities: [],
        displayedStartTime: null,
        displayedEndTime: null,
        displayedDate: null,
        unitPrice: productOrder.unitPrice
      }
      // Update the available quantities
      if (productOrder.product.type === ProductType.Product) {
        const minimumQuantity = Math.max(1, productOrder.quantity - 5);
        let maximumQuantity = productOrder.quantity + 5;
        if (!productOrder.selectedVariant.unlimitedQuantity) {
          maximumQuantity = Math.min(maximumQuantity, productOrder.selectedVariant.quantity);
        }
        for (let i = minimumQuantity; i <= maximumQuantity; i++) {
          this.tpl.productOrder[productOrder.id].availableQuantities.push(i);
        }
      } else if (productOrder.product.type === ProductType.Service) {
        const startTime: moment.Moment = moment(productOrder.selectedServiceTimeSlot)
          .tz(this.settings.timezone)
        const endTime: moment.Moment = moment(startTime).add(productOrder.duration, 'minutes')
        this.tpl.productOrder[productOrder.id].displayedStartTime = this._formatTimeSlot(startTime);
        this.tpl.productOrder[productOrder.id].displayedEndTime = this._formatTimeSlot(endTime);
        this.tpl.productOrder[productOrder.id].displayedDate = startTime.format('D MMMM YYYY');
      }
    }
    this.tpl.hasMixedBasket = this._orderService.hasMixedBasket(this.order);
    const products: ProductOrder[] = this.order.qartOrder.productOrders
      .filter(productOrder => productOrder.product.type === ProductType.Product);
    const services: ProductOrder[] = this.order.qartOrder.productOrders
      .filter(productOrder => productOrder.product.type === ProductType.Service);
    this.tpl.hasServicesOnly = products.length === 0 && services.length > 0;
    this.tpl.hasServices = services.length > 0;
    this.tpl.hasProducts = products.length > 0;
    for (const employee of this.employees) {
      this.tpl.employeesNames[employee._id] = employee.name;
    }
    this.tpl.subtotal = this.order.qartOrder.subtotal;
    // Compute the shipping costs, if any
    if (this.sessionData.shipping?.selectedRate) {
      if (this.sessionData.shipping.rateType === 'flat') {
        this.tpl.shippingCosts = (this.sessionData.shipping.selectedRate as FlatShippingRate).applicablePrice;
      } else if (this.sessionData.shipping.rateType === 'shippo') {
        this.tpl.shippingCosts = parseFloat((this.sessionData.shipping.selectedRate as ShippoShippingRate).amount);
      }
    } else {
      this.tpl.shippingCosts = 0;
    }
    this.tpl.couponDiscount = this.order.qartOrder.couponDiscount;
    this.tpl.showCouponDiscount = this.tpl.couponDiscount > 0;
    this.tpl.total = this.tpl.subtotal - this.tpl.couponDiscount + this.tpl.shippingCosts;
    this.tpl.basketIsEmpty = this.order.qartOrder.productOrders.length === 0;
    this.tpl.nProducts = this.order.qartOrder.productOrders.reduce((acc, curr) => acc + curr.quantity, 0);
    this.tpl.showShippingCosts = this.sessionData.shipping?.selectedRate != null;
    // Go back to the shopping page if the basket is empty
    const pageName: string = this._activatedRoute.snapshot?.firstChild?.data?.pageName || '';
    if (pageName) {
      this.tpl.showCheckoutButton = this.settings.stripeConnected && pageName !== 'checkout';
      this.tpl.showShoppingButton = true;
      // Go back to the shopping page if the basket is empty
      if (pageName === 'checkout' && this.order?.qartOrder?.productOrders?.length === 0) {
        //this.keepShopping();
      }
    }
    this.tpl.showPaymentMethods = this.settings.stripeConnected && pageName && pageName !== 'checkout';
    this.tpl.showEstimatedShippingCosts = this.sessionData.basket?.qartOrder?.numberOfProducts > 0 && pageName && pageName !== 'checkout';

    // Set the supported payment methods
    this.tpl.paymentMethods = [
      {
        id: 'visa',
        name: 'Visa'
      },
      {
        id: 'mastercard',
        name: 'Mastercard'
      },
      {
        id: 'american_express',
        name: 'American Express'
      },
      {
        id: 'google_wallet',
        name: 'Google Pay'
      },
      {
        id: 'bancontact',
        name: 'Bancontact'
      },
      {
        id: 'eps',
        name: 'EPS'
      },
      {
        id: 'giropay',
        name: 'Giropay'
      },
      {
        id: 'ideal',
        name: 'iDEAL'
      },
      {
        id: 'przelewy24',
        name: 'Przelewy24'
      },
      {
        id: 'sepa_debit',
        name: 'Sepa Debit'
      },
      {
        id: 'sofort',
        name: 'Sofort'
      }
    ];
    // Update the shipping costs
    this._refreshShippingCosts();
  }

  /**
   * Closes the basket drawer component using the `_fuseDrawerService`.
   */
  private _closeDrawer() {
    this._fuseDrawerService.getComponent('basket').close();
  }

  /**
   * Returns the applicable price for a given flat shipping rate based on the current order.
   * @param rate - The flat shipping rate to calculate the applicable price for.
   * @returns The applicable price for the given flat shipping rate.
   */
  private _getFlatRateApplicablePrice(rate: FlatShippingRate): number {
    // A price of -1 means that the price is undefined
    if (!rate.conditionsApply) {
      return rate.price;
    }
    let prices: number[] = rate.conditions.map(condition => {
      if (condition.criteria === 'nbItemsInBaskets') {
        const quantities: number[] = this.formQuantities.value;
        const nbItems: number = quantities.reduce((acc, curr) => acc + curr, 0);
        if (nbItems <= condition.threshold) {
          return condition.priceBelowThreshold;
        } else if (nbItems > condition.threshold) {
          return condition.priceAboveThreshold;
        } else {
          return -1;
        }
      } else if (condition.criteria === 'totalBasket') {
        const subtotal: number = this.order.qartOrder.subtotal;
        if (subtotal < condition.threshold) {
          return condition.priceBelowThreshold;
        } else if (subtotal >= condition.threshold) {
          return condition.priceAboveThreshold;
        } else {
          return -1;
        }
      } else if (condition.criteria === 'weightBasket') {
        const weight: number = this.order.qartOrder.weight;
        const range: any = condition.ranges.find(r => r.min <= weight && weight <= r.max);
        if (range) {
          return range.price;
        } else {
          return -1;
        }
      }
    });
    for (let i = 0; i < prices.length; i++) {
      if (prices[i] == null) {
        prices[i] = -1;
      }
    }
    prices = prices.filter(price => price !== -1);
    if (prices.length === 0) {
      return -1;
    } else {
      return Math.min(...prices);
    }
  }

  /**
   * Refreshes the shipping costs for the basket.
   * If the user country is known, the rates for that country are moved to the top.
   * The rates are sorted by price.
   * The shipping rates are stored in `this.tpl.shippingRates`.
   * @returns void
   */
  private _refreshShippingCosts(): void {
    if (!this.settings) {
      return;
    }
    this.tpl.shippingRates = [];
    // Get all the global rates per country
    for (const rate of this.settings.shippingFlatRates) {
      for (const country of rate.countryNames) {
        this.tpl.shippingRates.push({
          countries: [country],
          price: this._getFlatRateApplicablePrice(rate),
          estimatedDays: rate.estimatedDays,
          destinationType: rate.destinationType
        });
      }
    }
    // Get all the rates per country for the selected variant (overwrite the global rates if needed)
    /*
    for (const rate of this.product.shippingFlatRates) {
      for (const country of rate.countryNames) {
          // Check if there is already a rate for this country
          const existingRateIndex = this.tpl.shippingRates.findIndex(r => r.countries.includes(country));
          if (existingRateIndex !== -1) {
              // Remove the existing rate
              this.tpl.shippingRates.splice(existingRateIndex, 1);
          }
          this.tpl.shippingRates.push({
              countries: [country],
              price: this._getFlatRateApplicablePrice(rate),
              estimatedDays: rate.estimatedDays
          });
      }
    }
    */
    let userCountryName: string = null;
    // If the user country is known, then we don't merge this one with the rest
    if (this.userCountryCode) {
      // Get the name of a country from its country code
      userCountryName = this.countries.find(country => country.iso === this.userCountryCode)?.name || null;
    }
    // Merge the rates with same price and estimated days
    for (let i = 0; i < this.tpl.shippingRates.length; i++) {
      for (let j = i + 1; j < this.tpl.shippingRates.length; j++) {
        // If the country of the user is known, we don't merge the corresponding rate
        if (userCountryName) {
          if (this.tpl.shippingRates[i].countries.includes(userCountryName) || this.tpl.shippingRates[j].countries.includes(userCountryName)) {
            continue;
          }
        }
        // eslint-disable-next-line max-len
        if (this.tpl.shippingRates[i].price === this.tpl.shippingRates[j].price && this.tpl.shippingRates[i].estimatedDays === this.tpl.shippingRates[j].estimatedDays) {
          this.tpl.shippingRates[i].countries.push(...this.tpl.shippingRates[j].countries);
          this.tpl.shippingRates.splice(j, 1);
          j--;
        }
      }
    }
    // Sort the rates by price
    this.tpl.shippingRates.sort((a, b) => a.price - b.price);
    // If the country of the user is known, move the rates of the user's country to the top
    if (userCountryName) {
      const userCountryRates = this.tpl.shippingRates.filter(rate => rate.countries.includes(userCountryName));
      const otherRates = this.tpl.shippingRates.filter(rate => !rate.countries.includes(userCountryName));
      this.tpl.shippingRates = [...userCountryRates, ...otherRates];
    }
    this.tpl.isShippingDetailsAvailable = this.tpl.shippingRates.length > 0;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Updates the quantity of a product order in the basket and updates the basket in the session.
   * @param productOrderIdx - The index of the product order to update.
   * @param value - The new quantity value to set.
   */
  selectQuantity(productOrderIdx: number, value: number): void {
    this.order.qartOrder.productOrders[productOrderIdx].quantity = value;
    this._sessionService.updateBasket(this.order);
    // Mark for check
    this._changeDetectorRef.markForCheck();
  }

  /**
   * Removes a product from the basket.
   * @param productOrderId - The ID of the product order to remove.
   * @returns void
   */
  removeFromBasket(productOrderId: number): void {
    this._sessionService.removeProductFromBasket(productOrderId);
    // Mark for check
    this._changeDetectorRef.markForCheck();
  }

  /**
   * Edits a product in the basket.
   * @param productOrderId - The ID of the product order to edit.
   * @returns void
   */
  editBasketItem(productOrderId: number): void {
    this._sessionService.editProductInBasket(productOrderId);
    this.close.emit();
    // Mark for check
    this._changeDetectorRef.markForCheck();
  }

  /**
   * Navigates to the products page and closes the drawer.
   */
  discoverProducts(): void {
    this._router.navigate(['/products']);
    this._closeDrawer();
  }

  /**
   * Navigates to the checkout page and closes the drawer.
   */
  checkout(): void {
    this._router.navigate(['/checkout']);
    this._closeDrawer();
  }

  /**
   * Navigates to the products page and closes the basket drawer.
   */
  keepShopping(): void {
    this._router.navigate(['/products']);
    this._closeDrawer();
  }

  /**
   * Remove a coupon from the basket.
   * @returns void
   */
  removeCoupon(): void {
    const order: Order = cloneDeep(this.sessionData.basket);
    order.qartOrder.coupon = null;
    this._sessionService.updateBasket(order);
    // Mark for check
    this._changeDetectorRef.markForCheck();
  }
}

