<ng-container *transloco="let t; read: 'shared.basket-content'">
  
  <div class="flex flex-col gap-4">

    @if (tpl.basketIsEmpty) {
      <!-- Empty basket -->
      <div class="w-full text-3xl font-medium leading-6 tracking-tight text-center md:text-3xl">
        {{ t('empty-basket') }}
      </div>
      <!-- Browse product catalog button -->
      <button mat-flat-button
        aria-label="Browse product catalog"
        class="mt-6 fuse-mat-button-large w-full"
        [color]="'primary'"
        (click)="discoverProducts()">
        {{ t('btn-discover-products') }}
      </button>
    } @else {
      <!-- Basket content -->
      <form [formGroup]="form" class="flex flex-col gap-10 w-full">
        <!-- Basket item -->
        @for (productOrder of order?.qartOrder.productOrders; track productOrder.id; let productOrderIdx = $index; let lastProducOrder = $last) {
          <div
            [formArrayName]="'quantities'"
            class="p-2 pb-8 flex flex-col gap-6"
                    [ngClass]="{
                        'border-[1px] border-primary rounded-lg': highlightShippableProducts && productOrder.product.shippable
                    }">
            <div class="flex flex-row gap-6">
              <div class="basis-1/3 flex flex-col">
                @if (tpl.productOrder[productOrder.id]?.firstProductMinPicture?.url && tpl.productOrder[productOrder.id]?.firstProductMinPicture?.url !== '') {
                  @if (tpl.productOrder[productOrder.id]?.firstProductMinPicture.srcset) {
                    <img class="rounded-md"
                      [src]="tpl.productOrder[productOrder.id].firstProductMinPicture.url"
                      [srcset]="tpl.productOrder[productOrder.id].firstProductMinPicture.srcset"
                      [sizes]="tpl.productOrder[productOrder.id].firstProductMinPicture.sizes"
                      alt="Product photo">
                  } @else {
                    <img class="rounded-md"
                      [src]="tpl.productOrder[productOrder.id].firstProductMinPicture.url"
                      alt="Product photo"
                      loading="eager">
                  }
                } @else {
                  <div
                    class="flex items-center justify-center w-full h-full rounded overflow-hidden uppercase text-8xl font-bold leading-none bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                    {{ productOrder.product.name.charAt(0) }}
                  </div>
                }
                @if (productOrder.product.comment && productOrder.product.comment !== '') {
                  <div
                    [innerHTML]="productOrder.product.comment | safe: 'html'">
                  </div>
                }
              </div>
              <div class="basis-2/3">
                <h3 class="text-lg leading-normal mb-2">
                  {{ productOrder.product.name }}
                </h3>
                <!-- Selected attribute -->
                @if (productOrder.product.hasVariants && tpl.productAttributes[productOrder.product._id]) {
                  @for (attribute of tpl.productAttributes[productOrder.product._id]; track attribute) {
                    @if (tpl.variantItemOptions[productOrderIdx] && tpl.variantItemOptions[productOrderIdx][attribute.name]) {
                      <div
                        class="text-secondary text-md flex flex-col">
                        <div class="font-semibold">{{ attribute.name }}</div>
                        <div class="">{{ tpl.variantItemOptions[productOrderIdx][attribute.name] }}</div>
                      </div>
                    }
                  }
                }
                <!-- Selected custom fields -->
                @if (productOrder.product.customFields?.length > 0) {
                  @for (customField of productOrder.product.customFields; track customField; let customFieldIdx = $index) {
                    @if (tpl.variantItemCustomFieldValues
                      && tpl.variantItemCustomFieldValues[productOrderIdx]
                      && tpl.variantItemCustomFieldValues[productOrderIdx][customFieldIdx] !== '') {
                      <div
                        class="text-secondary text-md flex flex-col">
                        <div class="font-semibold">{{ customField.label }}</div>
                        <div class="">{{ tpl.variantItemCustomFieldValues[productOrderIdx][customFieldIdx] }}</div>
                      </div>
                    }
                  }
                }
                <!-- Delivered by -->
                @if (productOrder.product.type === ProductType.Service && productOrder.selectedEmployee !== 'any') {
                  <div
                    class="text-secondary text-md">
                    {{ t('service-delivered-by-employee', {
                    employeeName: tpl.employeesNames[productOrder.selectedEmployee]
                    }) }}
                  </div>
                }
                <!-- From, to -->
                @if (productOrder.product.type === ProductType.Service) {
                  <div class="text-secondary text-md">
                    {{ t('service-delivered-from-to-date', {
                    fromTime: tpl.productOrder[productOrder.id]?.displayedStartTime,
                    toTime: tpl.productOrder[productOrder.id]?.displayedEndTime,
                    date: tpl.productOrder[productOrder.id]?.displayedDate
                    }) }}
                  </div>
                }
                <!-- Quantity and price -->
                <div class="mt-4 flex flex-row items-center gap-0">
                  @if (showActions) {
                    <mat-form-field
                      subscriptSizing="dynamic"
                                    [ngClass]="{
                                        'w-20 mr-6': productOrder.product.type == ProductType.Product,
                                        'hidden': productOrder.product.type == ProductType.Service
                                    }">
                      <mat-select [formControlName]="productOrderIdx"
                        (selectionChange)="selectQuantity(productOrderIdx, $event.value)"
                        [attr.product-order-idx]="productOrderIdx" #quantitySelector>
                        @for (quantity of tpl.productOrder[productOrder.id]?.availableQuantities; track quantity) {
                          <mat-option
                            [value]="quantity">
                            {{ quantity }}
                          </mat-option>
                        }
                      </mat-select>
                    </mat-form-field>
                  }
                  @if (tpl.productOrder[productOrder.id]) {
                    <div
                      class="text-3xl tracking-tighter font-light text-left"
                                    [ngClass]="{
                                        'grow': productOrder.product.type == ProductType.Product,
                                        'basis-full': productOrder.product.type == ProductType.Service
                                    }">
                      @if (!showActions) {
                        <span>
                          {{ productOrder.quantity }} x {{ tpl.productOrder[productOrder.id].unitPrice | translocoCurrency: 'symbol' : { } : tpl.currency }}
                        </span>
                      } @else {
                        <span>
                          {{ tpl.productOrder[productOrder.id].unitPrice * form.get('quantities').at(productOrderIdx).value | translocoCurrency: 'symbol' : { } : tpl.orderCurrency }}
                        </span>
                      }
                    </div>
                  }
                </div>
                <!-- Shippable or not -->
                @if (productOrder.product.type === ProductType.Product && order?.qartOrder?.deliveryMethod === 'delivery' && tpl.hasMixedBasket) {
                  <div
                    class="flex flex-row" [ngClass]="{'shippable': productOrder.product.shippable}">
                    @if (productOrder.product.shippable) {
                      <mat-icon [svgIcon]="'heroicons_outline:truck'"></mat-icon>
                      <div class="">
                        {{ t('product-order-shippable') }}
                      </div>
                    }
                    @if (!productOrder.product.shippable) {
                      <mat-icon [svgIcon]="'heroicons_outline:home'"></mat-icon>
                      <div class="">
                        {{ t('product-order-not-shippable') }}
                      </div>
                    }
                  </div>
                }
              </div>
            </div>
            @if (showActions) {
              <div class="w-full flex flex-row gap-0">
                <button mat-ripple
                  aria-label="Remove from basket"
                            class="basis-1/3 flex flex-row items-center justify-center rounded-l-md h-[48px] border-y-[1px] border-l-[1px] border-slate-300 text-secondary hover:bg-slate-200 px-6 py-2
                            dark:text-slate-100 dark:hover:text-slate-800 dark:hover:bg-slate-200"
                  [matMenuTriggerFor]="deleteConfirmation" #triggerDelete="matMenuTrigger">
                            <mat-icon class="mr-2 text-slate-400" [ngClass]="{
                                'text-warn': triggerDelete.menuOpen
                            }" [svgIcon]="'heroicons_outline:trash'"></mat-icon>
                </button>
                <mat-menu [overlapTrigger]="false" #deleteConfirmation="matMenu">
                  <div class="grid grid-cols-2">
                    <div class="col-span-2 px-4 py-2">
                      {{ t('btn-remove') }}
                    </div>
                    <div class="ml-2 px-4 py-2 rounded-md flex flex-col justify-center items-center cursor-pointer hover:bg-slate-200 dark:hover:bg-slate-700"
                      (click)="true">
                      <mat-icon [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
                    </div>
                    <div class="mr-2 px-4 py-2 rounded-md flex flex-col justify-center items-center cursor-pointer hover:bg-slate-200 dark:hover:bg-slate-700"
                      (click)="removeFromBasket(productOrder.id)">
                      <mat-icon color="warn" [svgIcon]="'heroicons_outline:check'"></mat-icon>
                    </div>
                  </div>
                </mat-menu>
                <button mat-ripple
                  aria-label="Update selected product"
                            class="basis-2/3 flex flex-row items-center justify-center rounded-r-md h-[48px] border-[1px] border-slate-300 text-lg text-slate-600 hover:bg-slate-200 px-6 py-2
                                dark:text-slate-100 dark:hover:text-slate-800 dark:hover:bg-slate-200"
                  (click)="editBasketItem(productOrder.id)">
                  <mat-icon class="mr-3 text-slate-400" [svgIcon]="'heroicons_outline:pencil'"></mat-icon>
                  {{ t('btn-modify') }}
                </button>
              </div>
            }
          </div>
        }
      </form>
      
      @if (tpl.showCouponDiscount) {

        <!-- Sub-total -->
        <div class="w-full flex flex-row justify-between">
          <div class="text-xl">
            {{ t('header-sub-total') }}
          </div>
          <div class="text-xl">
            {{ tpl.subtotal | translocoCurrency: 'symbol' : { } : settings?.currency ?? 'EUR' }}
          </div>
        </div>

        <!-- Coupon Discount -->
        <div class="w-full flex flex-row justify-between">
          <div class="flex flex-col">
            <div class="text-xl">
              @if (order?.qartOrder?.coupon?.params?.discountType === 'percentage') {
                {{ t('header-coupon-discount-percentage', {
                  percentage: order?.qartOrder?.coupon?.params?.discount
                }) }}
              } @else {
                {{ t('header-coupon-discount') }}
              }
            </div>
            <div class="flex flex-row items-center cursor-pointer"
              (click)="removeCoupon()">
              <mat-icon color="warn" class="icon-size-5" svgIcon="close"></mat-icon>
              <span class="text-md">
                {{ t('link-remove-coupon') }}
              </span>
            </div>
          </div>
          <div class="text-xl">
            -{{ tpl.couponDiscount | translocoCurrency: 'symbol' : { } : settings?.currency ?? 'EUR' }}
          </div>
        </div>

      }
      <!-- Shipping costs -->
      @if (!tpl.hasServicesOnly && tpl.showShippingCosts) {
        <div class="w-full flex flex-row justify-between items-start gap-4">
          <div class="flex flex-col">
            <div class="text-xl">
              {{ t('header-shipping-costs') }}
            </div>
            @if (sessionData?.shipping?.address) {
              <div
                class="text-secondary">
                {{ t('delivered-at') }}
                <span class="font-bold">
                  {{ sessionData.shipping.address.street_no }} {{ sessionData.shipping.address.street1 }},
                  {{ sessionData.shipping.address.zip }} {{ sessionData.shipping.address.city }},
                  {{ sessionData.shipping.address.state }} {{ sessionData.shipping.address.country }}
                </span>
              </div>
            }
            @if (sessionData?.shipping?.selectedRate) {
              @if (sessionData?.shipping?.rateType === 'flat') {
                @if (sessionData?.shipping?.selectedRate?.destinationType === 'home') {
                  <div
                    class="text-secondary">
                    {{ t('flat-rate-home') }}
                  </div>
                }
                @if (sessionData?.shipping?.selectedRate?.destinationType === 'relay') {
                  <div
                    class="text-secondary">
                    {{ t('flat-rate-relay') }}:
                    @if (sessionData?.shipping?.selectedRate?.relayAddress && sessionData?.shipping?.selectedRate?.relayAddress !== '') {
                      <span
                        class="text-secondary">
                        {{ sessionData?.shipping?.selectedRate?.relayAddress }}
                      </span>
                    } @else {
                      <span
                        class="text-warn">
                        {{ t('missing-relay-address') }}
                      </span>
                    }
                  </div>
                }
                @if (sessionData?.shipping?.selectedRate?.showEstimatedDeliveryDate) {
                  <div
                    class="text-secondary">
                    {{ t('estimated-days-caption') }}
                    <span class="font-bold">
                      {{ t('estimated-days-number', {
                      days: sessionData?.shipping?.selectedRate.applicableEstimatedDays
                      }) }}
                    </span>
                  </div>
                }
              }
            }
            @if (sessionData?.shipping?.rateType === 'shippo') {
              <div class="text-secondary">
                {{ sessionData?.shipping?.selectedRate.provider }} {{ sessionData?.shipping?.selectedRate.servicelevel?.name ? ' - ' + sessionData?.shipping?.selectedRate.servicelevel?.name : '' }}
              </div>
              @if (sessionData?.shipping?.selectedRate?.estimated_days) {
                <div
                  class="text-secondary">
                  {{ t('estimated-days', {
                  days: sessionData?.shipping?.selectedRate?.estimated_days
                  }) }}
                </div>
              }
            }
          </div>
          <div class="text-xl">
            @if (tpl.shippingCosts > 0) {
              {{ tpl.shippingCosts | translocoCurrency: 'symbol' : { } : settings?.currency ?? 'EUR' }}
            }
            @if (tpl.shippingCosts === 0) {
              {{ t('free') }}
            }
          </div>
        </div>
        <mat-divider class="my-4"></mat-divider>
      }
      <!-- Total -->
      <div class="w-full flex flex-row justify-between">
        <div class="text-3xl font-medium tracking-tight">
          {{ t('header-total') }}
        </div>
        <div class="text-3xl font-medium tracking-tight">
          {{ tpl.total | translocoCurrency: 'symbol' : { } : settings?.currency ?? 'EUR' }}
        </div>
      </div>
      <!-- Estimated shipping costs -->
      @if (!tpl.hasServicesOnly && !tpl.showShippingCosts && tpl.showEstimatedShippingCosts) {
        @if (tpl.isShippingDetailsAvailable || settings?.shippoConnected) {
          <div>
            <div class="mt-4 w-full">
              <fuse-alert
                [type]="'basic'"
                [appearance]="'soft'"
                [showIcon]="true">
                <mat-icon class="icon-size-5"
                  fuseAlertIcon
                  [svgIcon]="'heroicons_solid:truck'">
                </mat-icon>
                <span fuseAlertTitle>{{ t('delivery-time-and-cost') }}</span>
                <div class="w-full grid grid-cols-5 mt-2">
                  @for (shippingRate of tpl.shippingRates; track shippingRate) {
                    <span class="col-span-3">
                      {{ shippingRate.countries.join(', ') }}
                      @if (shippingRate.destinationType === 'home') {
                        ({{ t('shipping-rate-destination-type-home') }})
                      }
                      @if (shippingRate.destinationType === 'relay') {
                        ({{ t('shipping-rate-destination-type-relay') }})
                      }
                    </span>
                    @if (shippingRate.price != null && shippingRate.price === 0) {
                      <span>{{ t('shipping-rate-free') }}</span>
                    }
                    @if (shippingRate.price != null && shippingRate.price > 0) {
                      <span>{{ shippingRate.price | translocoCurrency: 'symbol' : { } : settings?.currency ?? 'EUR' }}</span>
                    }
                    @if (shippingRate.price == null) {
                      <span>{{ t('shipping-rate-not-available') }}</span>
                    }
                    @if (shippingRate.showEstimatedDeliveryDate && shippingRate.price != null) {
                      <span>
                        &plusmn;{{ t('shipping-rate-time', {
                        nDays: shippingRate.estimatedDays
                        }) }}
                      </span>
                    }
                    @if (shippingRate.price == null) {
                      <span>{{ t('contact us') }}</span>
                    }
                  }
                  <span class="col-span-5 mt-3 text-secondary text-sm">{{ t('shipping-any-location-description') }}</span>
                </div>
              </fuse-alert>
            </div>
          </div>
        }
        <mat-divider class="my-4"></mat-divider>
      }
      @if (showActions) {
        <!-- Checkout button -->
        @if (tpl.showCheckoutButton) {
          <button
            mat-flat-button
            aria-label="Go to checkout"
            class="fuse-mat-button-large w-full"
            [color]="'primary'" [disabled]="false" (click)="checkout()">
            {{ t('btn-checkout') }}
          </button>
        }
        @if (tpl.showCheckoutButton && tpl.showShoppingButton) {
          <div
            class="w-full flex flex-row justify-center my-2 uppercase">
            {{ t('separator-or') }}
          </div>
        }
        <!-- Shopping button -->
        @if (tpl.showShoppingButton) {
          <button
            mat-flat-button
            aria-label="Keep shopping"
            class="fuse-mat-button-large w-full"
            [color]="'primary'" [disabled]="false" (click)="keepShopping()">
            {{ t('btn-keep-shopping') }}
          </button>
        }
        <mat-divider class="my-4"></mat-divider>
      }
    }

    <!-- Supported payment methods -->
    @if (tpl.showPaymentMethods) {
      <div class="mt-8 mb-4 sm:mb-8 flex flex-row flex-wrap gap-6 items-center">
        @for (paymentMethod of tpl.paymentMethods; track paymentMethod.name) {
          <div
            class="flex flex-row items-center justify-center bg-transparent rounded-md">
            <img class="min-h-8 min-w-8 h-8 w-8"
              width="32px"
              height="32px"
              src="assets/icons/payment/{{paymentMethod.id}}.webp"
              alt="{{paymentMethod.name}}"
              loading="lazy">
          </div>
        }
      </div>
    }

  </div>

</ng-container>